import React, {Component} from 'react';
import Body from '../body/body';
import NotFoundPage from '../notFoundPage/notFoundPage';
import {parseURL} from '../../helper/helper';

class FinalPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {},
      url: this.props.url ? this.props.url : window.location.href,
      isInvalidUrl: false
    };

    this.parseURL = parseURL.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    if (!this.state.url.includes('&') && !this.state.url.includes('?')) {
      this.setState({isInvalidUrl: true});
    } else {
      const urlObj = this.parseURL(this.state.url);
      const urlParams = urlObj.params;
      console.log('urlParams in finalPage', urlParams);
      this.setState({params: urlParams});
    }
  }

  render() {
    if (this.state.isInvalidUrl) {
      return <NotFoundPage />;
    }
    return (
      <Body
        TransactionID={this.state.params.clientRequestId}
        Type={this.state.params.t}
        Result={this.state.params.r}
        Message={this.state.params.mes}
        AccountHolder={this.state.params.accountHolder}
        AccountBankName={this.state.params.accountBankName}
        AccountNo={this.state.params.accountNo}
        AccountIban={this.state.params.accountIban}
        AccountBranch={this.state.params.accountBranch}
        UserID={this.state.params.customerId}
        ExternalID={this.state.params.pstransid}
        Amount={this.state.params.transactionAmount}
        Currency={this.state.params.currency}
        ServiceProviderId={this.state.params.sp}
        PaymentSystemId={this.state.params.ps}
        PaymentSystemName={this.state.params.psName}
        PaymentReference={this.state.params.paymentId}
        Code={this.state.params.code}
        isIframeComponent={this.props.isIframeComponent}
      />
    );
  }
}

export default FinalPage;
