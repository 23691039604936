import React from 'react';
import {useTranslation} from 'react-i18next';
import {returnMessage} from '../../helper/helper';
import {TRANSACTION_MESSAGE_TYPES} from '../../constants/common';
import CardList from '../cardList/cardList';
import transactionFailed from '../../assets/images/resultPageFail.svg';
import successTransaction from '../../assets/images/resultPageSucces.svg';
import transactionInProgress from '../../assets/images/resultPageInProcess.svg';
import './finalPageMessage.css';

const getImageSource = (paymentSystemName) => `https://cdn-py.apidigi.com/payment/${paymentSystemName}.png`;

const FinalPageMessage = (props) => {
  const {t} = useTranslation();
  let imageSrc;
  let imageAlt;
  let statusText;
  let messageClass;

  switch (props.Result) {
    case TRANSACTION_MESSAGE_TYPES.SUCCESS:
      imageSrc = successTransaction;
      imageAlt = 'Success Transaction';
      statusText = t('page.transactionSuccessful');
      messageClass = 'success';
      break;
    case TRANSACTION_MESSAGE_TYPES.ERROR:
      imageSrc = transactionFailed;
      imageAlt = 'Fail transaction';
      statusText = t('page.transactionFailed');
      messageClass = 'error';
      break;
    case TRANSACTION_MESSAGE_TYPES.IN_PROGRESS:
      imageSrc = transactionInProgress;
      imageAlt = 'In Progress transaction';
      statusText = t('page.transactionInProcess');
      messageClass = 'inProcess';
      break;
    default:
      return null;
  }

  const paymentSystemImage = props.PaymentSystemName && (
    <div className="card-logo-wrapper">
      <img
        src={getImageSource(props.PaymentSystemName)}
        alt={props.PaymentSystemName}
        width="100"
        height="100"
      />
    </div>
  );

  return (
    <>
      <picture className="transaction-status-img-wrapper">
        <img src={imageSrc} alt={imageAlt} />
      </picture>
      <div className={`${messageClass}`}>
        <h3 className="transaction-status">
          {statusText}
        </h3>
        {props.code && (
          <h3 className="transaction-status message">
            {t(returnMessage(props.code))}
          </h3>
        )}
        {props.Message && (
          <h4 className="message-info">{props.Message}</h4>
        )}
        {paymentSystemImage}
        <CardList {...props} />
      </div>
    </>
  );
};

export default FinalPageMessage;
